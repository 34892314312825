* {
    min-width: 0;
}

html, body {
    font-family: "Titillium Web", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #636466;
    overflow-x: hidden;
}

header, section {
    .container {
        max-width: 1212px;
        margin: 0 auto;
    }
}

.container {
    padding: 0;
    @media screen and (max-width: 1230px) {
        padding: 0 10px;
    }
}

h1, h2, h3, h4, h5, h6 {
    letter-spacing: normal;
}

sup {
    top: -0.4em;
    font-size: 65%;
    line-height: 100%;
}

a {
    cursor: pointer;
}

button, .button {
    background-color: $darkred;
    border-color: $darkred;
    border-radius: 30px;
    border-width: 2px;
    padding: 0px 40px 0px 30px;
    height: 48px;
    color: #fff;
    position: relative;
    margin-bottom: 0;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: none;
    font-weight: 700;
    padding-top: 14px;
    text-align: center;
    @media screen and (max-width: 768px) {
        line-height: 8px;
        height: 38px;
    }
    &::after {
        content: "";
        position: absolute;
        top: 14px;
        right: 8px;
        background-image: url('../img/content/button-arrow-white.svg');
        background-repeat: no-repeat;
        height: 18px;
        width: 11px;
        @media screen and (max-width: 768px) {
            top: 8px;
        }
    }
    &.download {
        width: 250px;
        padding-left: 10px;
        padding-right: 10px;
        min-width: 250px;
        &::after {
            background-image: url('../img/content/download-icon.svg');
            right: 20px;
            width: 15px;
        }
        &:hover::after {
            background-image: url('../img/content/download-red-icon.svg');
        }
    }
    &:hover {
        background-color: $white;
        border: 2px solid $darkred;
        color: $darkred;
        &::after {
            background-image: url('../img/content/button-arrow.svg');
        }
    }
}
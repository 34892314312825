.mobile-nav {
    display: none;
    font-size: 19px;
    line-height: 13px;
    font-weight: light;
    @media screen and (max-width: 1176px) {
        display: block;
        position: absolute;
        cursor: pointer;
        top: 78px;
        right: 10px;
        width: 50px;
        height: 40px;
        background-image: url(../img/header/menu-icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-repeat: no-repeat;
        padding-top: 10px;
        text-align: center;
        font-weight: 300;
        z-index: 999999;
        &.opened {
            height: 50px;
            font-size: 2.5em;
            padding-top: 14px;
            border-radius: 10px 10px 0 0;
            background-image: unset;
            color: $white;
            &.scrolled {
                height: 55px;   
            }
        }
        &.scrolled {
            position: fixed;
            top: 76px
        }
    }

    @media screen and (min-width: 640px) and (max-width: 1177px) {
        &.scrolled {
            top: 55px
        }
    }
    @media screen and (max-width: 768px) {
        &.scrolled {
            top: 43px
        }
    }
}

nav.primary {
    position: absolute;
    width: 100%;
    color: $white;
    font-size: 16px;
    line-height: 20px;
    z-index: 100;
    max-width: 940px;
    ul {
        li.dropdown {
            position: relative;
            @media screen and  (min-width: 768px) {
                &.opened {
                    ul {
                        display: block;
                    }
                }
            }
        }
        li.no-dropdowns {
            padding-right: 20px;
            a {
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width: 1176px) {
        display: none;
        &.opened {
            display: block;
            z-index: 101;
            height: auto;
            top: 135px;
            position: fixed;
            left: 10px;
            width: calc(100% - 20px);
            z-index: 999;
            padding-bottom: 0;
            border-radius: 0 0 15px 15px;
            max-width: calc(100% - 20px);
            ul {
                margin: 0;
                li {
                    padding-top: 0;
                    padding-bottom: 8px;
                    margin-bottom: 0;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    margin-bottom: 20px;
                    border-radius: 5px;
                    a {
                        width: 100%;
                        display: block;
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 500;
                        text-decoration: none;
                    }
                    &.access {
                        border-bottom: none!important;
                    }
                    &.no-dropdowns {
                        background-color: #0A7986;
                        a {
                            font-weight: 500;
                            padding: 20px 10px 10px;
                            display: block;
                            color: #fff;
                            font-size: 24px;
                        }
                    }
                    &.dropdown {
                        background-color: #0A7986;
                        span {
                            position: relative;
                            font-weight: 500;
                            padding: 20px 10px 8px;
                            display: block;
                        }
                        &::after {
                            content: "";
                            background-image: url(../img/content/caret.svg);
                            background-repeat: no-repeat;
                            position: absolute;
                            right: 20px;
                            top: 20px;
                            width: 20px;
                            height: 20px;
                            background-size: 20px 20px;
                        }
                        &.opened {
                            &::after {
                                transform: rotate(180deg);
                            }
                            ul {
                                display: block;
                                li a {
                                    font-size: 18px; line-height: 22px;
                                }
                            }
                            span::after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    &.mobile {
                        background-color: $darkblue;
                        color: $white;
                        width: calc(100% + 30px);
                        position: relative;
                        left: -15px;
                        top: 0px;
                        border-radius: 0 0 15px 15px;
                        padding: 15px 25px;
                        a { 
                            color: #0A7986;
                            &::after {
                                content: "›";
                                position: relative;
                                right: -5px;
                            }
                        }
                    }
                    &:hover {
                        font-weight: normal;
                    }
                    &:last-of-type.patient-linkout {
                        background-color: transparent;
                        border-radius: 0;
                        border-top: 2px solid #0A7986;
                        border-bottom: 2px solid #0A7986;
                    }
                    ul {
                        background-color: $white;
                        li {
                            padding-left: 20px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            margin-bottom: 0;
                            border-bottom: 1px solid #0A7986!important;
                            border-radius: 0;
                            a {
                                font-weight: 400;
                            }

                        }
                    }
                }
            }
        }
        &.scrolled {
            top: 125px;
            margin-bottom: 0;
            @media screen and (max-width: 1179px) {
                top: 125px
            }
            @media screen and (max-width: 768px) {
                top: 125px
            }
        }
    }
    ul, li {
        list-style: none;
        ul {
            display: none;
        }
        li {
            a {
                color: #0A7986;
                display: block;
            }
            &.opened, &.active, &:hover {
                font-weight: 700;
                position: relative;
                background: linear-gradient(0deg, rgba(193, 229, 232, 0.30) 0%, rgba(193, 229, 232, 0.00) 100%);
                @media screen and (max-width: 768px) {
                    background: #0A7986;
                }
                &::before {
                    content: "";
                    display: block;
                    height: 4px;
                    background: linear-gradient(105deg, rgba(193, 229, 232, 0.90) 18.82%, rgba(0, 161, 152, 0.90) 82.53%);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
                .active {
                    background: #fff;
                    &::before { 
                        display: none;
                    }
                    &::after {
                        content: "";
                        display: block;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: 10px;
                        height: 2px;
                        background: linear-gradient(105deg, rgba(193, 229, 232, 0.90) 18.82%, rgba(0, 161, 152, 0.90) 82.53%);
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                    }
                }
            }
            &:hover {
                @media screen and (max-width: 768px) {
                    &::before {
                        display: none;
                    }
                }
                li {
                    background: transparent;
                    @media screen and (min-width: 769px) {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    li:hover {
        font-weight: 700;
    }
    @media screen and (min-width: 1176px) {
        top: 90px;
        right: -20px;
        &.scrolled {
            top: 75px;
            left: 0;
            right: 0;
            max-width: 1212px;
            position: fixed;
            margin: 0 auto;
            z-index: 9999;
        }
        ul {
            display: flex;  
            justify-content: right;
            li {
                align-self: flex-end;
                height: 100%;
                min-height: 100%;
                position: relative;
                align-self: end;
                cursor: pointer;
                padding: 20px 40px 36px 20px;
                margin: 0 5px;
                color: $white;
                > a { color: $white; }
                &.dropdown {
                    position: relative;
                    a {
                        padding-bottom: 15px;
                        color: #0A7986;
                        text-align: center;
                        font-size: 15px;
                        line-height: 17px;
                        text-decoration: none;
                    }
                    &:last-of-type {
                        margin-right: 25px;
                    }
                    &::after {
                        content: "";
                        background-image: url(../img/content/caret.svg);
                        position: absolute;
                        height: 12px;
                        width: 15px;
                        right: 10px;
                        bottom: 40px;
                        background-repeat: no-repeat;
                    }
                    &:hover {
                        &::after {
                            bottom: 45px;
                            transform: rotate(180deg);
                        }
                    }
                    ul {
                        margin: 0;
                        position: absolute;
                        top: 76px;
                        left: 0;
                        width: 100%;
                        background-color: $white;
                        padding: 5px 0;
                        display: none;
                        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.45);
                        li {
                            margin: 0 0 10px;
                            padding-bottom: 10px;
                            font-weight: normal;
                            font-size: 13px;
                            padding: 10px;
                            margin-bottom: 0;
                            position: relative;
                            &::after {
                                content: "";
                                height: 1px;
                                display: block;
                                width: 100%;
                                left: 0;
                                background: linear-gradient(105deg, rgba(193, 229, 232, 0.90) 18.82%, rgba(0, 161, 152, 0.90) 82.53%);
                                position: absolute;
                            }
                            &:last-of-type {
                                margin-bottom: 0;
                                padding-bottom: 0;
                                border-bottom: none;
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                    &:hover {
                        ul {
                            display: block;
                        }
                    }
                }
                &:hover {
                    ul {
                        display: block;
                    }
                }
            }
        }
        span {
            display: block;
        }
        .patient-linkout {
            display: none;
        }
    }
}

.content {
    nav.primary {
        @media screen and (max-width: 1179px) {
            top: 135px;
            max-height: 500px;
            max-height: calc(100% - 312px );
            overflow-y: scroll;
        }
        &.scrolled {
            top: 70px;
            @media screen and (max-width: 1179px) {
                top: 125px
            }
            @media screen and (max-width: 768px) {
                top: 97px
            }
        }
    }
}

.modal-active {
    .mobile-nav {
        z-index: 9100;
    }
}

nav #nav-logo-scrolled {
    display: none;
}
nav.scrolled #nav-logo-scrolled {
    display: block;
    float: left;
    @media screen and (min-width: 640px) {
        margin-left: 10px;
    }
    @media screen and (max-width: 1176px) {
        display: none;
    }
}

#nav-logo-scrolled-mobile {
    display: none;
    @media screen and (min-width: 640px) {
        margin-left: 10px;
    }
    img {
        height: 55px;
    }
    @media screen and (max-width: 1176px) {
        display: block;
        
    }
}
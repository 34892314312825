.accordion, .accordion-faq {
    max-width: 100%;
    margin-bottom: 30px;
    border-radius: 8px;
    background: rgba(193, 229, 232, 0.30);
    padding: 25px 40px;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }

    h4 { 
        margin-top: 0!important;
    }

    a { cursor: pointer; }

    p.clickthrough {
        margin-bottom: 10px;
    }

    .network, .specialty {
        padding-left: 120px;
        padding-right: 50px;
        margin-bottom: 6px;
        position: relative;
        cursor: pointer;
        @media screen and (max-width: 768px) {
            padding-left: 0;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0px;
            width: 85px;
            height: 85px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            @media screen and (max-width: 768px) {
                width: 70px;
                height: 70px;
            }
        }
        &::after {
            content: "";
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("../img/content/toggle.svg");
            width: 33px;
            height: 33px;
            right: 0;
            transform: rotate(180deg);
            @media screen and (max-width: 768px) {
                top: 15px;
            }

            @media screen and (max-width: 500px) {
                top: 0px;
            }
        }
        &.rotate::after {
            transform: rotate(0deg);
        }
    }
    .network {
        padding-top: 7px;
        @media screen and (max-width: 768px) {
            padding-top: 90px;
            padding-right: 0;
        }
        &::before, &::after {
            @media screen and (max-width: 768px) {
                top: 0;
            }
        }
        &::after {
            @media screen and (max-width: 768px) {
                top: 15px;
            }
            @media screen and (max-width: 500px) {
                top: 5px;
            }
        }
    }
    .specialty {
        @media screen and (max-width: 768px) {
            padding-top: 90px;
            padding-right: 0;
        }
    }
    @media screen and (max-width: 768px) {
        h3 {
            font-size: 20px;
            line-height: 24px;
        }
    }
    h4, h5 {
        margin-bottom: 15px
    }
    p.description {
        padding-left: 120px;
        padding-right: 50px;
        min-height: 50px;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 18px;
        line-height: 22px;
        @media screen and (max-width: 768px) {
            padding-top: 10px;
            padding-left: 0;
            padding-right: 15px;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 30px;
        }
    }
    .network::before {
        background-image: url("../img/content/network.svg");
        @media screen and (max-width: 768px) {
            left: calc(50% - 35px);
        }
    }
    .specialty::before {
        background-image: url("../img/content/specialty.svg");
        @media screen and (max-width: 768px) {
            left: calc(50% - 35px);
        }
        top: 0;
    }
    li {
        list-style: none;
        padding-left: 40px;
        text-wrap: wrap;
        .icon {
            position: relative;
            display: inline-block;
            font-size: 16px;
            span {
                @media screen and (max-width: 430px) {
                    display: inline-block;
                }
            }
            &::before {
                content: "";
                position: absolute;
                left: -40px;
                width: 27px;
                height: 27px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
            &.phone {
                color: $grey;
                text-decoration: none;
                &::before {
                    background-image: url("../img/content/phone.svg");
                }
            }
            &.fax {
                color: $grey;
                text-decoration: none;
                &::before {
                    background-image: url("../img/content/fax.svg");
                }
            }
            &.mail::before {
                background-image: url("../img/content/mail.svg");
            }
            &.website::before {
                background-image: url("../img/content/website.svg");
            }
        }
    }
    .answer {
        padding: 0px 50px 50px;
        display: none;
        .row {
            margin-top: 35px;
            &:first-of-type {
                margin-top: 25px;
                @media screen and (max-width: 768px) {
                    margin-top: 0;
                }
            }
            ul {
                margin-bottom: 0;
                list-style: none;
                padding-left: 35px;
                li {
                    &::before {
                        display: none;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            padding: 10px 25px 25px;
        }
        &.opened {
            display: block;
        }
    }
}

h2.faq-header {
    margin-bottom: 5px;
    margin-top: 40px;
    @media screen and (max-width: 768px) {
        padding: 0 30px 0 0;
    }
}
.accordion {
    .faq-accordion {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: $green;
        &::after {
            top: 20px;
        }
    }
    p.description {
        margin-bottom: 0;
    }
    .answer {
        padding: 20px 0 10px;
        @media screen and (max-width: 768px) {
            padding: 20px 15px;
        }
        .row {
            &:first-of-type {
                margin-top: 0;
            }
            ul {
                padding-left: 30px;
            }
        }
    }
}

.accordion-faq {
    background-color: transparent;
    max-width: 100%;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
        padding: 0;
    }
    &::after {
        display: none;
    }
    .padding-top-0 {
        padding-top: 0!important;
    }
    .padding-top-20 {
        padding-top: 20px!important;
    }
    .column {
        &.website {
            padding-top: 12px;
            padding-left: 0;
            a {
                font-weight: 700;
            }
            &::before {
                background-image: url("../img/content/website.svg");
                width: 33px;
                height: 33px;
                top: 7px;
                left: -40px;
            }
        }
    
        &.phone {
            padding-top: 2px;
            padding-left: 0;
            margin-left: 70px;
            a {
                text-decoration: none;
                font-weight: 700;
            }
            &::before {
                background-image: url("../img/content/phone.svg");
                width: 33px;
                height: 33px;
                top: 3px;
                left: -40px;
            }
        }
    }
    .item {
        padding-top: 25px;
        &:first-of-type {
            padding-top: 0;
        }
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            display: block;
            background: linear-gradient(105deg, rgba(193, 229, 232, 0.90) 18.82%, rgba(0, 161, 152, 0.90) 82.53%);
        }
        h3 {
            position: relative;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; 
            color: $green;
            padding: 0 50px 0 0;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                padding: 0 30px 0 0;
            }
            &::after {
                content: "";
                background-image: url("../img/content/caret-white.svg");
                background-color: $lightred;
                background-repeat: no-repeat;
                width: 33px;
                height: 33px;
                display: block;
                border-radius: 50%;
                text-align: center;
                background-position: center;
                background-size: 60%;
                position: absolute;
                right: 0;
                top: -3px;
            }
        }
        .answer {
            padding-bottom: 25px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(193, 229, 232, 0.20) 98.36%);
            @media screen and (max-width: 768px) {
                padding-left: 0;
                padding-right: 0;
            }
            ul {
                padding-left: 15px;
                li {
                    list-style: disc;
                    padding-left: 0;
                    padding-top: 2px;
                    &::marker {
                        color: $green;
                    }
                }
            }
            h3 {
                color: $green;
                padding-left: 0;
                &::after {
                    display: none;
                }
            }
            .faq-column {
                padding: 20px 20px 20px 100px;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    width: 60px;
                    height: 60px;
                    background-image: url(../img/content/pill-bottle.svg);
                    background-repeat: no-repeat;
                    position: absolute;
                    left: 20px;
                    top: 20px;
                }
                &.thermometer {
                    &::before {
                        background-image: url(../img/content/thermometer.svg);
                    }
                }
            }
            .faq-callout {
                background-image: url(../img/content/galaxy.jpg);
                padding: 20px;
                position: relative;
                border-radius: 30px;
                margin-top: 30px;
                .patient-callout {
                    a.button {
                        &:hover {
                            color: #D13E4E;
                        }
                    }
                }
                .callout {
                    align-items: center;
                    margin-left: 0;
                    margin-right: 0;
                    width: 100%;
                    @media screen and (min-width: 640px) and (max-width: 1177px) {
                        flex-wrap: wrap;
                    }
                }
                @media screen and (max-width: 1000px) {
                    .column-20 {
                        width: 100%;
                        max-width: 100%;
                        flex: 0 0 100%;
                    }
                }
                img {
                    width: 175px;
                    height: 85px;
                    @media screen and (max-width: 1000px) {
                        width: 300px;
                    }
                }
                p {
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px; 
                    color: $white;
                    &.footer {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }
                @media screen and (max-width: 1000px) {
                    .column-25 {
                        width: 100%;
                        max-width: 100%;
                        flex: 0 0 100%;
                        text-align: center!important;
                        margin: 20px auto 0;
                        a.button {
                            height: 35px;
                            padding-top: 5px;
                            min-width: unset;
                            width: auto;
                            &::after {
                                top: 5px;
                            }
                        }
                            
                    }
                }
                a {
                    color:#B7DDE2;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 26px;
                    text-decoration-line: underline;
                    text-decoration-style: solid;
                    text-decoration-skip-ink: none;
                    text-decoration-thickness: auto;
                    text-underline-offset: auto;
                    text-underline-position: from-font;
                    &.button {
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 18px;
                        color: $white;
                        padding-right: 30px;
                        padding-left: 10px;
                        text-decoration: none;
                    }
                    &.small {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 22px;
                    }
                }
            }
        }
        &.item-opened {
            h3 {
                &::after {
                    transform: rotate(180deg);
                }
            }
            .phone-callout {
                .row {
                    margin-top: 0;
                    @media screen and (min-width: 1000px) {
                        .website {
                            padding-left: 40px;
                            &::before {
                                left: 0;
                            }
                        }
                    }
                    @media screen and (max-width: 1000px) {
                        width: unset!important;
                        flex-wrap: wrap!important;
                        .email {
                           padding-top: 0!important;
                        }
                        .website, .phone {
                            margin-left: 0;
                            padding: 10px 0 0 0;
                            width: 250px!important;
                            max-width: 250px!important;
                            text-align: left;
                            padding-left: 50px;
                            &::before {
                                left: 0;
                            }
                        }
                        .website, .email, .phone, .or {
                            align-self: flex-start;
                            flex: 0 0 100% !important;
                            max-width: 100%!important;
                            min-height: unset;
                        }
                        .website {
                            padding-top: 15px !important;
                        }
                        .email {
                            padding-left: 50px;
                            padding-bottom: 0;
                            &::before {
                                top: 0;
                            }
                        }
                        .or {
                            padding-top: 10px;
                            padding-bottom: 10px;
                            padding-left: 100px;
                            text-align: left!important;
                        }
                    }
                }
            }
        }
    }
}
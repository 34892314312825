header {
    z-index: 10;
    // transition: all 0.15s ease-in;
    position: fixed;
    width: 100%;
    display: block;
    font-size: 13px;
    font-family: "Titillium Web", Arial;
    border-radius: 0px 0px 8px 0px;
    background-image: url(../img/content/galaxy.jpg);
    background-position: center;
    border-radius: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #012F4B;
    z-index: 1000;

    @media screen and (max-width: 1177px) {
        transform: translateY(-27px);
        .row {
            &:first-of-type {
                padding-left: 10px;
                padding-right: 10px;
                background-color: unset!important;
                width: calc(100% + 20px);
                position: relative;
                left: -10px;
            }
        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 10px;
        background-image: url(../img/header/shadow.svg);
        display: block;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: -10px;
    }

    .for-patients {
        display: none;
        @media screen and (max-width: 1177px) {
            display: block;
            position: absolute;
            top: 13px;
            right: 10px;
            color: $darkred;
            a {
                color: $white;
                font-weight: normal;
                font-style: normal;
            }
        }
    }

    .connect-with-a-rep {
        display: none;
        @media screen and (max-width: 1177px) {
            display: block;
            position: absolute;
            cursor: pointer;
            top: 80px!important;
            right: 80px;
            color: $white;
            background-color: $darkred;
            padding: 10px;
            border-radius: 3px;
            font-size: 11px;
            line-height: 13px;
            font-weight: 700;
            width: 120px;
            padding-right: 20px;
            background-image: url(../img/header/rep-icon.svg);
            background-repeat: no-repeat;
            background-position: right bottom;
            background-position-x: 80px;
            background-size: contain;
            a {
                color: $white;
                font-size: 11px;
                line-height: 13px;
                text-decoration: none;
            }
        }
        @media screen and (max-width: 768px) {
            top: 75px!important;
            width: 105px;
            background-position-x: 65px;
        }
    }

    .healthcare {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        font-style: italic;
        color: $white;
        @media screen and (max-width: 1177px) {
            font-size: 11px;
            line-height: 13px;
            padding-top: 8px;
            text-align: center;
        }

        @media screen and (max-width: 639px) {
            padding-bottom: 3px;
        }
    }

    .pi {
        @media screen and (max-width: 1177px) {
            padding-bottom: 3px;
            ul {
                text-align: center;
            }
        }
        
        @media screen and (max-width: 639px) {
            display: flex;
            justify-content: center;
        }
    }

    .supplemental-nav {
        display: block;
        padding-top: 20px;
        padding-bottom: 10px;
        opacity: 1;
        height: 85px;
        @media screen and (max-width: 1177px) {
            height: unset;
        }
        @media screen and (max-width: 1000px) {
            &.row .column-20 {
                flex: 0 0 40%;
                max-width: 40%;
            }
        }
        .mobile-nav {
            display: none;
            @media screen and (max-width: 1000px) {
                display: block;
                position: fixed;
                top: 78px;
                right: 10px;
                font-weight: 300;
                z-index: 9100;
                background-color: url(../img/header/menu-icon.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        @media screen and (max-width: 768px) {
            padding-top: 5px;
            padding-bottom: 0;
        }
        img {
            height: 55px;
            opacity: 1;
            @media screen and (max-width: 768px) {
                height: 42px;
            }
        }
        @media screen and (max-width: 639px) {
            .connect-with-a-rep {
                top: 63px!important;
                display: block;
            }
        }
    }

    &.scrolled {
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.35);
        border-radius: 0;
        .connect-with-a-rep.extra {
            display: none!important;
        }
        
        @media screen and (max-width: 1177px) {
            .pi { padding-top: 2px; }
        }

        @media screen and (max-width: 639px) {
            top: 3px;
        }
    }

    &:not(.scrolled) {
        transition: all 0.15s ease-out;
        position: absolute;
        background: transparent;
        background-color: transparent;
        transform: translateY(0);
        &::before, &::after {
            visibility: hidden;
        }
        &::before {
            background-size: 100% 100%;
        }
        .supplemental-nav {
            opacity: 0;
            display: none;
            img {
                opacity: 0;
            }
            .connect-with-a-rep {
                display: block;
            }
        }
        
        @media screen and (max-width: 639px) {
            .connect-with-a-rep.extra {
                display: block;
                top: 75px;
            }
        }
    }

    .container {
        .row:first-of-type {
            @media screen and (max-width: 397px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .row:first-of-type {
            background: rgba(183, 221, 226, 0.15);
            border-radius: 0 0 8px 0;
            position: relative;
            @media screen and (max-width: 1177px) {
                background-color: unset;
                border-radius: 0;
            }
            @media screen and (max-width: 639px) {
                min-height: 56px;
            }
            &::before {
                content: "";
                background: rgba(183, 221, 226, 0.15);
                width: 50%;
                position: absolute;
                left: -50%;
                top: 0;
                height: 100%;
                @media screen and (max-width: 1177px) {
                    display: none;
                }
            }
        }
        nav {
            margin-bottom: 0;
            ul {
                margin-bottom: 0;
                display: flex;
                justify-content: right;
                position: relative;
                right: -10px;
                li {
                    list-style: none;
                    margin-bottom: 0;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 10px;
                    a {
                        @media screen and (min-width: 1177px) and (max-width: 1207px) {
                            letter-spacing: -0.1px;
                        }
                        color: $white;
                        text-decoration: none;
                        line-height: 1;
                        padding-right: 10px;
                        position: relative;
                        font-weight: 600;
                        color: #C1E5E8;
                        &::after {
                            content: "|";
                            position: relative;
                            left: 10px;
                            color: $white;
                            @media screen and (max-width: 1177px) {
                                left: 12px;
                            }
                            @media screen and (max-width: 768px) {
                                left: 5px;
                            }
                        }
                    }
                        
                    &:last-of-type {
                        padding: 0;
                        a {
                            font-weight: 700;
                            color: $white;
                            background-color: $darkred;
                            padding-right: 50px;
                            position: relative;
                            background-image: url("../img/header/rep-icon.svg");
                            background-repeat: no-repeat;
                            background-position: right bottom;
                            border-radius: 0 0 8px 0;
                            height: 100%;
                            width: 100%;
                            display: block;
                            padding-top: 20px;
                            padding-bottom: 15px;
                            padding-left: 10px;
                            padding-right: 60px;
                            &::after {
                                content: unset;
                            }
                        }
                    }

                    @media screen and (min-width: 640px) and (max-width: 1177px) {
                        .extra {
                            display: none!important;
                        }  
                    }

                    &:nth-of-type(3) {
                        a {
                            &::after {
                                content: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 640px) and (max-width: 1177px) {
        .row {
            display: block!important;
            &.supplemental-nav {
                padding-top: 0;
                padding-bottom: 0;
            }
            &:first-of-type {
                left: 0;
                top: -5px;
            }
            .healthcare {
                text-align: center;
                padding-top: 10px;
            }
            .column-66, .column-34 {
                display: block;
                max-width: 100%;
                flex: 0 0 100%!important;
                max-width: 100%!important;
                .connect-with-a-rep.extra {
                    top: 92px;
                }
                ul {
                    display: block!important;
                    text-align: center;
                    padding-top: 10px;
                    max-width: 100%;
                    padding-top: 5px;
                    li {
                        display: inline-block;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        &:last-of-type {
                            display: none;
                        }
                    }
                }
                .for-patients {
                    display: none;
                }
            }
        }
    }
    @media screen and (max-width: 639px) {
        nav {
            ul {
                right: unset!important;
                justify-content: center!important;
                li {
                    width: auto!important;
                }
            }
        }
        .column-66, .column-34 {
            flex: 0 0 100%!important;
            max-width: 100%!important;
            li {
                a {
                    font-size: 11px;
                    line-height: 13px;
                    letter-spacing: -0.1px;
                }
            }
        }
        .column-66 {
            ul {
                li{
                    padding-left: 0!important;
                    padding-right: 0;
                    width: 50%;
                    padding-top: 5px!important;
                    padding-bottom: 4px!important;
                    &:first-of-type {
                        display: none;
                    }
                    &:last-of-type {
                        display: none;
                    }
                    a {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 400px) {
        .column-66, .column-34 {
            li {
                a {
                    letter-spacing: -0.2px;
                }
            }
        }
    }
    @media screen and (max-width: 389px) {
        .column-66, .column-34 {
            li {
                a {
                    font-size: 10px;
                    line-height: 13px;
                }
            }
        }
        .for-patients {
            font-size: 10px;
            line-height: 13px;
        }
    }
}

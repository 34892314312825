.hero {
    padding-top: 40px;
    margin-bottom: 0;
    font-family: "Titillium Web", Arial;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;
    &.home {
        a.button:hover {
            color: #D13E4E;
        }
    }
    &::after {
        content: "";
        height: 200px;
        background: linear-gradient(0deg, #022641 0%, rgba(2, 38, 65, 0.00) 100%);
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 2;
    }
    .background-video {
        position: absolute;
        right: -115px;
        top: 0px;
        width: 700px;
        height: 800px;
        z-index: -1;
        @media screen and (min-width: 640px) and (max-width: 1177px) {
            right: calc(50% - 200px);
            top: 100px;
            width: 400px;
            height: 400px;
        }
        @media screen and (max-width: 768px) {
            top: -500px;
            width: 768px;
            min-height: auto;
            left: calc(50% - 384px);
            right: 0;
            z-index: -1;
            height: unset;
        }
    }
    @media screen and (min-width: 1001px) {
        img#background-video {
            width: 950px;
            height: auto;
            top: calc(50% - 300px);
            right: 0;
            
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1000px)  {
        img#background-video {
            width: 800px;
            height: 800px;
            left: calc(50% - 400px);
        }
    }
    @media screen and (max-width: 768px) {
        padding-top: 65px;
        .button-wrap {
            text-align: center;
        }
    }

    .footnotes {
        position: relative;
        color: #C1E5E8;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
        letter-spacing: -0.24px;
        margin-top: 30px;
        background: unset;
        .footnote {
            display: block;
        }
    }

    .nav-header-link {
        position: relative;
        @media screen and (min-width: 1178px) {
            top: 40px;
        }
        @media screen and (max-width: 1177px) {
            top: 30px;
        }
        @media screen and (max-width: 768px) {
            top: 0;
        }
        img {
            z-index: 99999;
        }
    }
    
    &.subpage {
        background: transparent;
        background-image: url(../img/hero/subpage.jpg);
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        min-height: 370px;
        @media screen and (max-width: 768px) {
            background-image: url(../img/hero/subpage-mobile.jpg);
            background-size: cover;
        }
        @media screen and (min-width: 1300px) {
            &.connect-a-rep {
                background-position-x: 100%;
            }
        }
        &::after {
            height: 50px;
        }
        h1 {
            color: $white;
            display: inline-block;
            width: 100%;
            @media screen and (min-width: 1000px) {
                padding-right: 200px;
            }
        }
        .nav-header-link {
            img {
                width: 278px;
                height: 83px;
                @media screen and (max-width: 768px) {
                    width: 175px;
                    height: 52px;
                }

                @media screen and (max-width: 380px) {
                    width: 141px;
                    height: 42px;
                    margin-top: 8px;
                }
            }
        }
    }
    .nav-header-link {
        position: relative;
        z-index: 11;
        display: inline-block;

        @media screen and (max-width: 768px) {
            img { width: 180px; }
        }

        @media screen and (max-width: 380px) {
            img { width: 141px; }
        }
    }
    h1 {
        margin-top: 100px;
        small {
            color: #C1E5E8;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px; /* 122.222% */
            letter-spacing: -0.36px;
            display: block;
            margin-bottom: 10px;
        }
    }
    &.subpage {
        h1 {
            text-transform: none;
            padding-bottom: 50px;
            max-width: 1015px;
            font-weight: 400;
            font-size: 42px;
            line-height: 42px;
            @media screen and (min-width: 769px) {
                position: relative;
                top: 20px;
            }
            @media screen and (max-width: 768px) {
                font-size: 36px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px;
                padding-bottom: 120px;
                margin-top: 20px
            }
        }
    }
    a.button {
        z-index: 11;
        letter-spacing: 0;
        @media screen and (max-width: 768px) {
            min-width: 145px;
            font-size: 13px;
            padding-left: 10px;
            padding-right: 25px;
        }
    }
    .press-release {
        margin-right: 30px;
        @media screen and (max-width: 768px) {
            margin-right: 10px;
        }

        @media screen and (max-width: 389px) {
            margin-bottom: 10px;
        }

    }
    .highlight {
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 118.182% */
        letter-spacing: -0.44px;
        color: $white;
        margin-top: 16px;
        margin-bottom: 24px;
        z-index: 15;
        position: relative;
        max-width: 640px;
        small {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 116.667% */
            letter-spacing: -0.24px;
            margin-top: 5px;
            display: block;
        }
    }
    .callout {
        background: rgba(255, 255, 255, 0.15);
        color: $white;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin-top: 80px;
        z-index: 10;
        position: relative;
        @media screen and (max-width: 768px) {
            margin-top: 60px;
        }
        &::before, &::after {
            content: "";
            display: block;
            height: 2px;
            background: linear-gradient(105deg, rgba(193, 229, 232, 0.90) 18.82%, rgba(0, 161, 152, 0.90) 82.53%);
            width: 100%;
            top: 0;
        }
        &::after{
            top: unset;
            bottom: 0;
        }
        > div {
            padding: 20px 40px;
            @media screen and (max-width: 768px) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .callout-with-button {
        border-radius: 25px;
        color: $darkblue;
        font-size: 28px;
        line-height: 32px;
        position: relative;
        margin-top: 50px;
        margin-bottom: 0;
        background-color: $lightblue;
        
        @media screen and (max-width: 1230px) {
            background-color: #EDF7F8;
            margin-top: 20px;
            background-image: url("../img/header/rep-icon-green-mobile.svg");
            background-size: 91px 116px;
            background-position: bottom left;
            background-position-y: 25px;
            background-position: 15px;
            background-repeat: no-repeat;
            z-index: 1;
        }

        @media screen and (min-width: 640px) and (max-width: 1230px) {
            background-size: contain;
            background-position-y: 15px;
        }
        
        .container {
            padding-left: 0;
            padding-right: 0;
        }

        .row {
            @media screen and (max-width: 768px) {
                flex-wrap: wrap;
            }
        }

        .column {
            position: relative;
            &:first-of-type {
                padding: 25px 25px 25px 145px;
                font-size: 28px;
                line-height: 32px;
                background-image: url("../img/header/rep-icon-green.svg");
                background-repeat: no-repeat;
                background-position: left;
                background-position-x: 20px;
                background-size: contain;
                @media screen and (max-width: 1230px) {
                    background-image: unset;
                    font-size: 18px;
                    line-height: 22px;
                    padding-bottom: 0;
                    max-width: 100%;
                    padding: 20px 5px 0px 110px;
                }

                @media screen and (max-width: 768px) {
                    padding: 20px 5px 0px 145px;
                }
                @media screen and (max-width: 650px) {
                    padding: 20px 5px 0px 110px;
                }

                @media screen and (max-width: 768px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }

            &:last-of-type {
                padding: 25px;
                @media screen and (min-width: 640px) and (max-width: 1230px) {
                    padding-left: 5px;
                    margin-top: 15px;
                }
                @media screen and (max-width: 768px) {
                    padding-top: 10px;
                }
                .button {
                    position: relative;
                    z-index: 20;
                    width: 100%;
                    @media screen and (max-width: 1230px) {
                        min-width: 200px;
                        font-size: 14px;
                        padding-left: 15px;
                        padding-right: 25px;
                    }
                    @media screen and (max-width: 768px) {
                        margin-left: 135px;
                    }
                    @media screen and (max-width: 650px) {
                        margin-left: 85px;
                    }
                }
            }

            &.column-75 {
                @media screen and (max-width: 768px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
p.connect {
    color: $grey;
    font-size: 18px;
    line-height: 22px;
    margin-top: 60px;
}
form {
    margin-bottom: 60px;
    select, input[type=text], input[type=select], input[type=email] {
        max-width: 500px;
        width: 100%;
        padding: 8px 8px 6px;
        border: 1px solid #707070;
        color: #636466;
        background: #fff;
        border-radius: 0;
        height: 36px;
        margin-bottom: 5px;
        &:active, &:visited {
            border: 2px solid $darkblue;
        }
        &:focus {
            border: 2px solid #00A39A;
        }
    }
    label {
        display: block;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        color: #666666;
        span {
            font-weight: normal;
        }
    }
    input.small {
        width: 235px;
    }
    .custom-select {
        &.selected {
            font-style: normal;
        }
    }
    .required_text {
        font-size: 13px;
        margin: 0px 0 15px;
        font-weight: 600;
        font-style: italic;
    }
    .opt-in {
        color: $grey;
        font-size: 18px;
        line-height: 22px;
        margin-top: 20px;
    }
    .required_asterisk,
    .pristine-error {
        color: $lightred;
    }
    
    .not-active {
        display: none;
    }

    .has-danger .not-active {
        display: inline;
    }

    .pristine-error {
        color: $lightred;
        font-size: 16px;
        line-height: 21px;
        font-style: italic;
    }
    .has-danger input, .has-danger select {
        border: 2px solid $lightred;
    }

    .has-danger {
        input[type="checkbox"] {
            &::before {
                content: "";
                display: block;
                width: 13px;
                height: 13px;
                border: 1px solid $lightred;
                border-radius: 3px;
            }
        }
    }
    
    .pristine-error {
        font-size: 16px;
        line-height: 21px;
    }
    select {
        appearance: none;
        background-image: url("../img/content/caret-blue.svg");
        background-size: 20px 14px;
        background-repeat: no-repeat;
        background-position: right center;
        background-position-x: 98%;
        font-size: 16px;
        font-style: italic;
        color: $grey;
        max-width: 500px;
        border: solid 1px $darkblue;
        &:focus {
            background-image: url("../img/content/caret-blue.svg");
        }
        &:active {
            background-image: url("../img/content/caret-reverse.svg");
        }
        &.selected {
            color: $grey;
        }
    }
    .checkbox {
        display: inline-block;
        width: 200px;
        height: 25px;
        label, input {
            display: inline;
        }
        label {
            font-weight: normal;
        }
        input {
            &:last-of-type {
                margin-left: 40px;
            }
        }
    }
    button {
        padding-top: 0;
        &::after {
            top: 16px;
            @media screen and (max-width: 768px) {
                top: 8px;
            }
        }
    }
    .btn-hodler {
        @media screen and (max-width: 768px) {
            margin: 30px auto 0!important;
        }   
    }
}
.container {
    max-width: 1212px;
}

h1 {
    font-size: 32px;
    line-height: 36px;
    color: $white;
    font-weight: 700;
}

h2 {
    font-size: 28px;
    line-height: 32px;
    color: $darkblue;
    font-weight: 700;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
    }
}
h3 {
    font-size: 24px;
    line-height: 28px;
    color: $white;
    font-weight: 500;
    @media screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
    }
}
h4 {
    font-size: 20px;
    line-height: 26px;
    color: $darkblue;
    font-weight: 700;
    @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 22px;
    }
}
h5 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
}

.main, .subpage, .home {
    p {
        &.footnote, &.reference {
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.5px;
            text-align: left;
            margin: 0 0 4px;
            position: relative;
            font-size: 12px;
            line-height: 14px;
            &.with-margin {
                margin: 0 0 4px;
            }   
            &.gutter {
                padding-left: 5px;
                font-size: 12px;
                sup.first {
                    position: absolute;
                    left: 0px;
                    top: 1px;
                }
                sup.asterisk {
                    font-size: 100%;
                }
            }
        }
    }
    a {
        color: $darkred;
        text-decoration: underline;
        &.button  {
            color: $white;
            text-decoration: none;
            letter-spacing: 0;
        }
        &.no-background {
            font-size: 26px;
            line-height: 30px;
            color: $white;
            font-weight: 700;
            text-align: right;
            padding-right: 25px;
            position: relative;
            margin-top: 40px;
            margin-bottom: 50px;
            display: block;
            text-decoration: none;
            @media screen and (min-width: 1200px) {
                position: relative;
                right: -5rem;
            }
            @media screen and (max-width: 1199px) {
                position: relative;
                right: 2rem;
            }

            @media screen and (max-width: 1000px) {
                font-size: 20px;
                line-height: 24px;
                padding-right: 50px;
                width: 100%;
                text-align: right;
                right: 0;
            }
            &::after {
                content: "";
                background-image: url(../img/content/caret-white.svg);
                width: 33px;
                height: 33px;
                display: block;
                position: absolute;
                background-repeat: no-repeat;
                background-color: $lightred;
                background-size: auto;
                background-position: center;
                border-radius: 50%;
                right: -25px;
                top: 0;
                padding: 10px 0 10px 10px;
                transform: rotate(-90deg);
                text-align: center;
                @media screen and (max-width: 1000px) {
                    top: calc(50% - 16.5px);
                    right: 0;
                }
            }
        }
    }
    .green-gradient {
        background: linear-gradient(105deg, rgba(193, 229, 232, 0.20) 18.82%, rgba(0, 161, 152, 0.20) 82.53%);
        padding: 14px 38px;
        h4 {
            margin-top: 0;
        }
        .arrows {
            padding: 50px 25px 50px 100px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 768px) {
                padding: 25px 25px 25px 50px;
                margin-bottom: 0;
                p {
                    text-align: left;
                }
            }
            &::before {
                content: "";
                display: block;
                height: 50px;
                width: 70px;
                position: absolute;
                background-repeat: no-repeat;
                left: 20px;
                top: calc(50% - 25px);
                background-image: url(../img/content/arrows.svg);
                @media screen and (max-width: 768px) {
                    width: 40px;
                    height: 50px;
                    background-size: contain;
                    left: 0;
                }
            }
        }
        table {
            border-bottom: 1px solid $green;
            th {
                background-color: $green;
                color: $white;
                font-weight: 700;
                &:first-of-type {
                    border-right: 1px solid $white;
                }
                &:last-of-type {
                    border-left: 1px solid $white;
                }
            }
            th, td {
                padding: 10px;
            }
            tr {
                &:nth-of-type(odd) {
                    background-color: $white;
                }
                &:nth-of-type(even) {
                    background-color: $mutedgreen;
                }
                td {
                    &:first-of-type {
                        border-right: 1px solid $green;
                    }
                    &:last-of-type {
                        border-left: 1px solid $green;
                    }
                }
            }
        }
        @media screen and (max-width: 1177px) {
            padding: 20px;
            flex-wrap: wrap;
            .column {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
    }
}

.main {
    background: linear-gradient(180deg, #022641 0%, #1C4F75 73.5%);
    h1 {
        font-size: 32px;
        line-height: 36px;
        color: $white;
        margin: 20px auto;
        @media screen and (max-width: 768px) {
            font-size: 20px;
            line-height: 24px;
        }
    }
    h2 {
        font-size: 26px;
        line-height: 30 px;
        color: $green;
        margin: 0 auto 20px;
        @media screen and (max-width: 768px) {
            font-size: 18px;
            line-height: 22px;
        }
    }    
    p {
        font-size: 18px;
        line-height: 22px;
        color: $white;
        margin: 0 auto 40px;
        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .vs {
        font-size: 32px;
        line-height: 36px;
        color: $white;
        text-align: center;
        font-weight: 700;
        vertical-align: middle;
        justify-self: middle;
    }

    #review {
        h1 {
            @media screen and (max-width: 768px) {
                font-size: 20px;
                line-height: 24px;
            }
        }
        .button {
            @media screen and (max-width: 768px) {
                font-size: 14px;
                max-width: 220px;
                min-width: 220px;
                padding-left: 20px;
                padding-right: 50px;
            }
            &:hover {
                color: $darkred;
            }
        }
    }
}

.subpage {
    &#main {
        background: #fff;
    }
    a.button {
        &:hover {
            color: $darkred;
        }
    }
    h2 {
        margin-top: 60px;
        @media screen and (max-width: 768px) {
            margin-top: 20px;
        }
    }
    h3 {
        color: $green;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }
    h4 {
        margin-top: 40px;
    }
    ul {
        padding-left: 15px;
        li {
            font-size: 18px;
            line-height: 22px;
            color: #636466;
            position: relative;
            list-style: none;
            padding-left: 0;
            @media screen and (max-width: 768px) {
                font-size: 16px;
                line-height: 20px;
                padding-left: 5px;
            }
            &::before {
                content: "•";
                color: $green;
                position: absolute;
                left: -15px;
            }
        }
    }
    p {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 22px;
        @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .primary, .secondary, .dosing-icon {
        padding-left: 100px;
        position: relative;
        @media screen and (max-width: 768px) {
            padding-left: 80px;
        }
        p {
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 22px;
        }
        strong {
            color: $green;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            background-image: url(../img/content/progression-icon.svg);
            height: 75px;
            width: 75px;
            background-repeat: no-repeat;
        }
    }

    .dosing-icon {
        strong {
            color: #666;
        }
    }

    .primary, .secondary {
        left: 10px;
        @media screen and (max-width: 768px) {
            margin-bottom: 50px;
            left: 0;
        }
    }

    .secondary {
        &::before {
            background-image: url(../img/content/time-icon.svg);
        }
    }

    .clock {
        &::before {
            background-image: url(../img/content/clock-icon.svg);
        }
    }

    .water {
        &::before {
            background-image: url(../img/content/water-icon.svg);
        }
    }

    .crush {
        &::before {
            background-image: url(../img/content/crush-icon.svg);
        }
    }

    .pillbox {
        h4 {
            margin: 0;
            margin-top: 8px;
        }
        p {
            font-weight: normal;
        }
        &::before {
            background-image: url(../img/content/pill-box-icon.svg);
        }
    }

    .temp {
        h4 {
            margin: 0;
            margin-top: 8px;
        }
        p {
            font-weight: normal;
        }
        &::before {
            background-image: url(../img/content/temp-icon.svg);
        }
    }

    .card-icon, .publication-icon {
        padding-left: 120px!important;
        font-size: 26px;
        @media screen and (max-width: 768px) {
            padding-left: 20px!important;
        }
        h3 {
            font-size: 26px;
            line-height: 30px;
            @media screen and (max-width: 768px) {
                padding-left: 120px!important;
                font-size: 20px;
                line-height: 24px;
            }
        }
        p {
            font-weight: 400;
        }
        a {
            font-size: 18px;
            font-weight: 700;
            @media screen and (max-width: 1000px) {
                &.button {
                    width: 180px;
                    min-width: 180px;
                }
            }
        }
        &::before {
            width: 88px;
            height: 88px;
            background-size: contain;
            left: 20px;
            top: 20px;
            background-image: url(../img/content/card-icon.svg);
        }
    }
    

    .publication-icon {
        @media screen and (max-width: 768px) {
            p {
                @media screen and (max-width: 768px) {
                    padding-left: 120px!important;
                }
            }
        }
        &::before {
            background-image: url(../img/content/publication-icon.svg);
        }
    }

    .dosing-icon {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        align-self: top;
        padding-left: 80px;
        margin: 30px 20px 0;
        @media screen and (max-width: 768px) {
            min-height: 50px;
            display: block;
            font-size: 16px;
            line-height: 20px;
            margin-left: 0;
        }
        
        span {
            position: relative;
            top: 5px;
        }
    }

    .downloads {
        text-align: left;
        margin-top: 40px;
        margin-bottom: 0px;
        &.last-row {
            margin-bottom: 0;
            padding-bottom: 60px;
        }
        img {
            width: 141px;
            margin-bottom: 15px;
        }
        h3 {
            text-align: left;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; 
            margin-bottom: 15px;
        }
        a.download {
            line-height: 25px;
        }
        p:last-of-type {
            margin-bottom: 20px;
        }
        @media screen and (max-width: 768px) {
            justify-content: center;
            a.download {
                width: 156px;
                min-width: 156px;
                font-size: 16px;
                line-height: 23px;
                padding-top: 15px;
                padding-left: 0px;
                &::after {
                    background-size: 13px 13px;
                    top: 18px;
                    right: 15px;
                }
            }
        }
        .column {
            margin-bottom: 0;
            @media screen and (max-width: 768px) {
                width: 100%;
                max-width: 100%;
                .button-wrap {
                    text-align: center;
                    margin: 0 auto;
                }
            }
        }
    }

    .blue-boxes {
        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            .column {
                flex: 0 0 100%;
                max-width: 100%;
                h3 { 
                    min-height: 96px;
                }
            }
        }
        @media screen and (min-width: 550px) and (max-width: 768px) {
            .column {
                h3 { 
                    font-size: 26px;
                    line-height: 30px;
                }
            }
        }
    }

    .blue-box {
        background-color: #EDF7F8;
        border-radius: 10px;
        margin: 0 10px;
        padding: 20px;
        p, a {
            font-size: 26px;
            line-height: 30px;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 50px;
            width: calc(100% - 20px);
        }
    }

    .endpoints {
        margin-top: 50px;
        margin-bottom: 20px;
        h4 {
            margin-top: 0;
            position: absolute;
            top: -40px;
            left: 0px;
        }
    }

    #secondary-endpoint {
        margin-top: 100px;
    }

    &.results {
        .primary, .secondary {
            padding-bottom: 30px;
            @media screen and (max-width: 768px) {
                margin-bottom: 0;
            }
            &::before {
                height: 93px;
                width: 93px;
                background-size: contain;
                @media screen and (max-width: 768px) {
                    height: 70px;
                    width: 70px;
                }
            }
        }
    }

    .oral-green-background {
        background: linear-gradient(105deg, rgba(193, 229, 232, 0.20) 18.82%, rgba(0, 161, 152, 0.20) 82.53%);
        padding: 20px!important;
        h4 {
            margin-top: 0;
        }
    }

    &.access {
        .patient-access-support-kit {
            ul {
                @media screen and (max-width: 768px) {
                    margin-bottom: 0;
                }
            }
        }
        .patient-download {
            max-width: 170px!important;
            @media screen and (max-width: 768px) {
                margin: 0 auto 10px;
            }
        }
        .download-now {
            @media screen and (max-width: 768px) {
                text-align: center;
            }
        }
        .phone-callout {
            @media screen and  (min-width: 768px) and (max-width: 1000px) {
                margin: 25px 40px;
            }
            @media screen and (max-width: 768px) {
                margin-left: 10px;
            }
            .row {
                margin-top: 0;
                padding-left: 50px;
                @media screen and (min-width: 1000px) {
                    .website {
                        padding-left: 40px;
                        &::before {
                            left: 0;
                        }
                    }
                }
                @media screen and (max-width: 1000px) {
                    padding-left: 10px;
                    width: unset!important;
                    flex-wrap: wrap!important;
                    .email {
                       padding-top: 0!important;
                    }
                    .website, .phone {
                        margin-left: 0;
                        padding: 10px 0 0 0;
                        width: 250px!important;
                        max-width: 250px!important;
                        text-align: left;
                        padding-left: 50px;
                        &::before {
                            left: 0;
                        }
                    }
                    .website, .email, .phone, .or {
                        align-self: flex-start;
                        flex: 0 0 100% !important;
                        max-width: 100%!important;
                        min-height: unset;
                    }
                    .email {
                        padding-left: 50px;
                        padding-bottom: 0;
                        &::before {
                            top: 0;
                        }
                    }
                    .or {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        padding-left: 100px;
                        text-align: left!important;
                    }
                }
            }
        }
    }
    &.access, &.resources {
        .downloads { 
            a.download {
                font-size: 16px;
                width: 155px;
                min-width: 155px;
                padding: 10px 25px;
                height: 50px;
                text-align: left;
                &::after {
                    top: 15px;
                }
            }
            h4 { margin-top: 0px; }
        }
        .secondary-header {
            @media screen and (max-width: 768px) {
                padding-top: 35px;
            }
        }
    }

    
    &.resources {
        @media screen and (max-width: 1230px) {
            .button.download {
                line-height: unset
            }
            h3 {
                min-height: 48px;
            }
        }
        @media screen and (max-width: 768px) {
            .downloads { 
                margin-top: 20px;

                .column-50 {
                    margin-top: 40px;
                    &:first-of-type {
                        margin-top: 0;
                    }
                }
                img {
                    width: 200px;
                    margin-bottom: 20px!important;
                }
                .resource-galaxy {
                    .column-25 {  text-align: center;  }
                    img { width: 64px; }
                }
            }
            h3 {
                min-height: unset;
            }
        }
        .resource-galaxy {
            border-radius: 16px;
            padding: 30px;
            background-image: url(../img/content/galaxy.jpg);
            background-repeat: no-repeat;
            align-items: center;
            .column-25 {
                img {
                    margin-bottom: 0;
                }
            }
            .column-75 {
                p {
                    color: $white;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    &:last-of-type {
                        color: #B7DDE2;
                        font-weight: 400;
                        a {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}
.dosing {
    table a { font-weight: bold; }
    @media screen and (max-width: 768px) {
        #container-dosing-top {
            display: flex;
        }
    }
    .table-dosage-modification.mobile {
        th { text-align: left!important; vertical-align: middle; background-color: $green!important; color: #FFFFFF!important; font-weight: 700!important; }
        .bg-green-light { background-color: #E9F5F6!important; }
        .bg-white { background-color: #FFFFFF; }
        td:first-child {
            background-color: #FFFFFF; color: $grey; font-weight: normal; text-align: left; vertical-align: middle;
        }
    }
}

.mod-moa {
    .oncogenesis {
        max-width: 840px; 
        margin: 0 auto;
        h4 {
            text-align: left!important;
        }
    }
}

.callout {
    background: linear-gradient(105deg, rgba(193, 229, 232, 0.20) 18.82%, rgba(0, 161, 152, 0.20) 82.53%);
    &.nccn-callout {
        margin-left: 100px;
        margin-right: 100px;
        width: calc(100% - 200px);
        @media screen and (min-width: 640px) and (max-width: 1177px) {
            margin-left: 20px;
            margin-right: 20px;
            width: calc(100% - 40px);
        }
        @media screen and (max-width: 768px) {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            .column {
                width: 100%;
                max-width: 100%;
                padding: 25px;
                margin: 0;
                &:first-of-type {
                    padding-bottom: 10px;
                }
                &:last-of-type {
                    padding-top: 10px;
                }
            }
        }
    }
    div {
        &.column-20 {
            padding: 0;
            img {
                position: relative;
                transform: scale(1.03);
                top: 2px;
                left: -30px;
                &.nccn-image {
                    left: -15px;
                    @media screen and (min-width: 641px) and (max-width: 1177px) {
                        left: -5px;
                        top: -5px;
                    }
                }
                @media screen and (max-width: 768px) {
                    transform: scale(1);
                    left: unset;
                    margin: 0 auto;
                }
            }
        }

        &.column-80 {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 50px 20px 0;
            position: relative;
            &::before {
                content: "";
                background-image: url(../img/content/bar.svg);
                background-repeat: no-repeat;
                position: absolute;
                top: 25px;
                left: -25px;
                height: 70%;
                width: 3px;
                background-size: cover;
            }
            @media screen and (min-width: 641px) and (max-width: 1177px) {
                &::before {
                    content: "";
                    background-image: url(../img/content/bar.svg);
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 20px;
                    left: -10px;
                    height: 70%;
                    width: 3px;
                    background-size: cover;
                }
            }
            @media screen and (max-width: 640px) {
                &::before {
                    content: "";
                    background-image: url(../img/content/bar.svg);
                    background-repeat: no-repeat;
                    position: absolute;
                    top: -90px;
                    left: 46%;
                    height: 100%;
                    
                    transform: rotate(90deg);
                }
                padding: 20px 50px;
            }
        }
        
        p {
            color: $darkblue;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            padding: 0;
            margin: 0;
        }
    }
}

.molecular-profiling {
    .footnotes {
        margin-top: 60px;
    }
}

.patient-accordion {
    margin-top: 120px;
    @media screen and (min-width: 769px) {
        margin-left: 100px;
        margin-right: 100px;
    }
    .patient {
        background-color: #0389B9;
        background-image: url(../img/content/galaxy.jpg);
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 20px;
        position: relative;
        border-radius: 30px;
        min-height: 144px;
        cursor: inherit;
        .patient-footnote {
            color: #FFF;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.24px;
            margin-top: 15px;
            margin-bottom: 40px;
            font-size:18px;
            line-height: 20px;
        }
        @media screen and (max-width: 768px) {
            background-size: cover;
        }
        &::after {
            content: "";
            background-image: url(../img/content/button-arrow-white.svg);
            background-repeat: no-repeat;
            width: 32px;
            height: 32px;
            display: block;
            position: absolute;
            top: 50px;
            right: 20px;
            background-color: $darkred;
            border-radius: 50%;
            text-align: center;
            background-position: center;
            transform: rotate(90deg);
            @media screen and (max-width: 768px) {
                top: unset;
                bottom: 20px;
                left: calc(50% - 16px);
            }
        }
        .patient-header {
            cursor: pointer;
            p:first-of-type {
                padding-right: 50px;
                @media screen and (max-width: 768px) {
                    padding-right: 0;
                }
            }
            .column {
                padding-left: 250px;
                position: relative;
                @media screen and (max-width: 768px) {
                    padding-left: 0;
                }
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: -95px;
                    left: 0;
                    width: 220px;
                    height: 260px;
                    background-image: url(../img/content/stacie.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    @media screen and (max-width: 768px) {
                        top: -100px;
                        left: calc(50% - 110px);
                        background-image: url(../img/content/stacie-mobile.png);
                    }
                }
            }
        }
        &.stacie {
            .patient-header {
                .column {
                    &::before {
                        top: -76px;
                        @media screen and (max-width: 768px) {
                            top: -120px;
                        }
                    }
                }
            }
        }
        &.steven {
            .patient-header {
                .column {
                    &::before {
                        background-image: url(../img/content/steven.png);
                        top: -75px;
                        @media screen and (max-width: 768px) {
                            width: 250px;
                            left: calc(50% - 123px);
                            top: -80px;
                            background-image: url(../img/content/steven-mobile.png);
                        }
                    }
                }
            }
        }
        &.gary {
            .patient-header {
                .column {
                    &::before {
                        background-image: url(../img/content/gary.png);
                        top: -94px;
                        @media screen and (max-width: 768px) {
                            width: 250px;
                            left: calc(50% - 113px);
                            top: -80px;
                            background-image: url(../img/content/gary-mobile.png);
                        }
                    }
                }
            }
            .patient-info {
                padding-top: 10px;
            }
        }
        h2 {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            color: $white;
        }
        h3 {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; 
            color: #B7DDE2;
        }
        h4 {
            margin-top: 20px;
            margin-bottom: 10px;
            color: $white;
            @media screen and (max-width: 768px) {
                &:first-of-type {
                    text-align: center;
                    padding-top: 100px;
                }
            }
        }
        p {
            color: $white;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 28px; 
            @media screen and (max-width: 768px) {
                text-align: center;
                font-size: 18px;
                line-height: 24px;
                &:first-of-type {
                    padding-bottom: 40px;
                }
            }
        }
        ul {
            li {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                color: $white;
                &::before {
                    color: $green;
                    left: -15px;
                    top: -1px;
                }
            }
            margin-bottom: 0;
        }
        margin-bottom: 50px;
        .patient-info {
            display: none;
            cursor: auto;
            .callout.patient-callout {
                margin-left: 0;
                margin-right: 0;
                width: 100%;
            }
            @media screen and (max-width: 1177px) {
                .column {
                    width: 100%;
                    max-width: 100%;
                    flex: 0 0 100%;
                    &.right {
                        text-align: center!important;
                    }
                }
                .patient-callout {
                    flex-wrap: wrap;
                    p {
                        &:first-of-type {
                            padding-bottom: 20px;
                        }
                    }
                }
                a.button {
                    height: 35px;
                    max-width: 200px;
                    margin: 0 auto 40px;
                    padding-top: 5px;
                    &::after {
                        top: 5px;
                    }
                }
            }
            .info {
                h3 {
                    margin-top: 20px;
                }
                ul {
                    margin-bottom: 20px;
                }
            }
            &.open {
                display: block;
            }
        }
        margin-bottom: 100px;
        &:last-of-type {
            margin-bottom: 80px;
        }
        &.patient-opened {
            background-size: cover;
            &::after {
                transform: rotate(270deg);
            }
        }
    }
}

.callout {
    &.patient-callout {
        width: 95%;
        width: calc(100% - 80px);
        background: unset;
        margin: 30px auto 10px;
        &.with-galaxy {
            background-image: url(../img/content/galaxy-callout.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 20px 20px;
            border-radius: 30px;
            align-items: center;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            .column {
                margin-bottom: 0;
                p {
                    padding-top: 0;
                }
                &.column-10 {
                    @media screen and (max-width: 768px) {
                        max-width: 100%;
                        width: 100%;
                        text-align: center!important;
                        img {
                            margin: 20px 0;
                        }
                    }
                }
                &.column-25 {
                    @media screen and (max-width: 768px) {
                        max-width: 100%;
                        width: 100%;
                        text-align: center!important;
                        margin-top: 20px;
                    }
                }
                a.button {
                    @media screen and (max-width: 768px) {
                        height: 35px;
                        padding-top: 5px;
                        min-width: unset;
                        width: auto;
                    }
                    &::after {
                        top: 15px;
                        @media screen and (max-width: 768px) {
                            top: 5px;
                        }
                    }
                }
            }
        }
        p {
            font-size: 22px;
            font-style: normal;
            color: $white;
            font-weight: 600;
            line-height: 26px; 
            padding-top: 10px;
        }
        a.button {
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px; 
            min-width: 150px;
            height: 50px;
            margin-top: 5px;
            &::after {
                top: 15px;
            }
        }
        &.access-support {
            padding: 30px 40px;
            &.with-galaxy {
                background-image: url(../img/content/galaxy.jpg);
            }
            @media screen and (max-width: 768px) {
                padding: 20px;
            }
            .row {
                margin-bottom: 30px;
                .box {
                    .row {
                        @media screen and (min-width: 640px) and (max-width: 1177px) {
                            flex-wrap: wrap;
                        }
                    }
                }
            }
            .column {
                align-items: center;
                &.column-50 {
                    &.tick, &.prescription {
                        margin-right: 40px;
                    }
                }
            }
            h3 {
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                color: $white;
            }
            .column {
                color: $white;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px;
            }
            .column-50 {
                display: flex;
                align-items: center;
            }
            .call {
                font-weight: 400;
                a {
                    font-weight: 700;
                }
            }
            .box {
                padding: 20px 40px;
                &.water {
                    margin-left: 10px;
                    margin-right: 40px;
                    width: calc(50% - 30px);
                    max-width: calc(50% - 30px);
                    @media screen and (max-width: 1180px) {
                        margin-right: 10px;
                    }
                    @media screen and (max-width: 768px) {
                        width: 100%;
                        margin-left: 0;
                    }
                }
                &.crushed-pills {
                    width: calc(50% - 30px);
                    max-width: calc(50% - 30px);
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                }
                @media screen and (max-width: 768px) {
                    padding: 20px;
                    &::before {
                        top: 0;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
                .row {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    .mouse {
                        font-family: "Titillium Web";
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 26px;
                        padding-left: 90px;
                        @media screen and (max-width: 1180px) {
                            padding-top: 80px;
                            padding-left: 0;
                            padding-right: 0;
                            width: 100%!important;
                            max-width: 100%!important;
                            flex: 0 0 100%;
                            &::before {
                                top: -5px;
                            }
                        }
                    }
                    a {
                        color: #B7DDE2;
                        &.cta-button {
                            color: #fff;
                        }
                    }
                    .mouse-content {
                        @media screen and (max-width: 1180px) {
                            width: 100%;
                            max-width: 100%;
                            flex: 0 0 100%;
                            padding: 0;
                            text-align: center;
                        }
                        a {
                            &:hover {
                                color: $darkred;
                            }
                        }
                    }
                }
            }
        }
    }
    &.taking {
        padding: 40px!important;
        @media screen and (max-width: 768px) {
            padding: 20px!important;
        }
        h3 { 
            margin-bottom: 0;
        }
        .column {
            margin-top: 0;
            margin-bottom: 0;
            @media screen and (max-width: 768px) {
                width: 100%;
                max-width: 100%;
                margin-right: 0;
                &:first-of-type {
                    margin-bottom: 20px!important;
                }
                &:last-of-type {
                    margin-bottom: 10px!important;
                }
            }
        }
        .row:last-of-type {
            margin-bottom: 0;
        }
        .box {
            padding: 30px 60px!important;
            &::before {
                margin: 20px;
            }
            div.gradient-border {
                height: 2px;
                width: 100%;
                background: linear-gradient(105deg, rgba(193, 229, 232, 0.9) 18.82%, rgba(0, 161, 152, 0.9) 82.53%);
                position: absolute;
                top: 0;
                left: 0;
            }
            span {
                padding-left: 50px;
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px;
                display: block;
            }
        }
    }
}

.arrows {
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    line-height: 28px;
    color: $darkblue;
    position: relative;
    text-align: center;
    margin-bottom: 60px;
    span {
        font-style: normal;
        margin-left: 20px;
        @media screen and (max-width: 768px) {
            margin-left: 0;
        }
    }
    strong {
        font-style: normal;
    }
    @media screen and (max-width: 768px) {
        .column {
            padding-left: 70px;
            text-align: left;
        }
    }
    &::before {
        content: "";
        background-image: url(../img/content/arrows.svg);
        width: 100px;
        height: 100px;
        display: block;
        position: absolute;
        left: 90px;
        top: -10px;
        background-repeat: no-repeat;
        @media screen and (max-width: 768px) {
            left: 0;
            top: calc(50% - 25px);
            width: 50px;
            height: 50px;
            background-size: contain;
        }
    }
    &.with-galaxy {
        background-image: url(../img/content/galaxy-tall.png);
        background-size: 100% 100%;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: $white;
        border-radius: 16px;
        margin: 50px 100px;
        padding: 30px 10px 30px 120px;
        text-align: left;
        @media screen and (max-width: 768px) {
            margin: 20px;
        }
        &::before {
            top: 16px;
            left: 30px;
            @media screen and (max-width: 768px) {
                top: calc(50% - 30px)!important
            }
        }
        &.two-lines {
            &::before {
                top: 25px;
            }
        }
    }
    &.access-arrows {
        @media screen and (min-width: 640px) and (max-width: 1177px) {
            width: 80%;
            left: 10%;
        }
        &::before {
            left: 50px;
            @media screen and (min-width: 640px) and (max-width: 1177px) {
                left: -40px;
            }
            @media screen and (max-width: 639px) {
                left: 10px;
            }
        }
        h3 {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            color: #1B3667;
        }
    }
}

.brochure-section {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: $white;
    background-image: url(../img/content/galaxy-tall.png);
    background-repeat: no-repeat;
    padding: 30px;
    border-radius: 30px;
    padding-left: 170px;
    padding-right: 80px;
    position: relative;
    margin-left: 100px;
    margin-right: 100px;
    width: calc(100% - 200px);
    align-items: center;
    background-size: 100% 100%;
    @media screen and (max-width: 1019px) {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 150px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        flex-wrap: wrap;
        .column {
            width: 100%;
            flex: 0 0 100%!important;
            max-width: 100%!important;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; 
            text-align: center;
            flex: 0 0 100%;
            &:first-of-type {
                padding-top: 80px;
                margin-bottom: 20px;
            }
        }
        &.trial-results {
            margin-top: 150px!important;
        }
    }
    &::before {
        content: "";
        display: block;
        background-image: url(../img/content/voranigo-patient-profiles-summary.png);
        width: 140px;
        height: 185px;
        position: absolute;
        background-size: contain;
        left: 0;
        top: -35px;
        @media screen and (max-width: 1019px) {
            top: -100px;
            left: calc(50% - 70px);
        }
    }
    &.patient {
        @media screen and (max-width: 1019px) {
            margin-top: 190px!important;
        }
        @media screen and (max-width: 768px) {
            margin-top: 160px!important;
        }
        &::before {
            top: -25px;
            @media screen and (max-width: 1019px) {
                top: -100px;
            }
            @media screen and (max-width: 768px) {
                top: -100px;
            }
            background-image: url(../img/content/voranigo-patient-brochure-icon.png);
        }
    }
    
    &.treatment {
        @media screen and (max-width: 1019px) {
            margin-top: 190px!important;
        }
        @media screen and (max-width: 768px) {
            margin-top: 160px!important;
        }
        &::before {
            top: -20px;
            @media screen and (max-width: 1019px) {
                top: -100px;
            }
            @media screen and (max-width: 768px) {
                top: -100px;
            }
            background-image: url(../img/content/treatment-management-guide-icon.png);
        }
    }

    &.flashcard {
        margin-top: 100px!important;
        @media screen and (max-width: 1019px) {
            margin-top: 200px!important;
        }
        @media screen and (max-width: 768px) {
            margin-top: 160px!important;
        }
        &::before {
            top: -20px;
            background-image: url(../img/content/indigo-trial-flashcard-icon.png);
            @media screen and (max-width: 1019px) {
                top: -100px;
            }
        }
    }

    a.button {
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; 
        min-width: 150px;
        height: 50px;
        margin-top: 5px;
        &::after {
            top: 14px;
        }
        @media screen and (max-width: 768px) {
            height: 35px;
            max-width: 200px;
            margin: 0 auto 10px;
            padding-top: 5px;
            &::after {
                top: 5px;
            }
        }
    }
}

.trial-efficacy {
    background-image: url(../img/content/galaxy.jpg);
    padding: 20px;
    position: relative;
    border-radius: 16px;
    margin-top: 45px;
    background-size: cover;
    .column.box {
        padding-left: 150px;
        padding: 30px 30px 30px 150px;
        margin: 0 10px 30px;
        position: relative;
        @media screen and (max-width: 1177px) {
            padding: 130px 20px 20px;
            margin-left: 0;
            margin-right: 0;
        }
        &::before {
            content: "";
            position: absolute;
            left: 30px;
            top: 30px;
            background-image: url(../img/content/chart.svg);
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            @media screen and (max-width: 1177px) {
                left: calc(50% - 40px);
            }
        }
        &.ttni {
            &::before {
                background-image: url(../img/content/ttni.svg);
            }
        }
        span {
            font-weight: 400;
        }
    }
    h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: $white;
    }
    h4 {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        color: $white;
        margin-top: 0;
    }
    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        color: $white;
        &.reference {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            text-align: left!important;
        }
        &:last-of-type {
            text-align: center;
            margin: 0 auto;
        }
    }
    a.button {
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; 
        min-width: 150px;
        height: 50px;
        margin-top: 5px;
        @media screen and (max-width: 768px) {
            height: 35px;
            padding-top: 5px;
            min-width: unset;
            width: auto;
        }
        &::after {
            top: 15px;
            @media screen and (max-width: 768px) {
                top: 5px;
            }
        }
    }
}

.video-callout {
    .row {
        align-items: center;
    }
    background: linear-gradient(105deg, rgba(193, 229, 232, 0.20) 18.82%, rgba(0, 161, 152, 0.20) 82.53%);
    margin-top: 60px;
    .column-40 {
        margin: 0;
        padding: 0;
    }
    .column-60 {
        padding-left: 20px;
        h2 {
            margin-top: 0;
        }
    }
    @media screen and (max-width: 768px) {
        .column {
            max-width: 100%!important;
            width: 100%!important;
        }
        .column-60 {
            h2 {
                margin-top: 20px;
            }
        }
    }
}

.green-background {
    background: rgba(193, 229, 232, 0.30);
    padding-top: 60px;
    h2 {
        margin-top: 0;
    }
}

.patient-download {
    max-width: 170px;
    @media screen and (max-width: 768px) {
        max-width: 100%;
        text-align: center;
    }
}

.with-legend {
    .legend {
        position: relative;
        display: block;
        text-align: right;
        padding-right: 20px;
        max-width: 95%;
        @media screen and (max-width: 768px) {
            max-width: 100%;
        }
        span {
            &::after {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                background-color: $green;
                position: absolute;
                right: 0;
                top: 5px;
            }
            &.primary-legend {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px; 
                color: $green;
            }
            &.placebo-legend {
                color: #666;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                &::after {
                    background-color: #666;
                }
            }
        }
    }
}

.video-container {
    width: 80%;
    margin-bottom: 20px;
    margin-top: 10px;
    max-width: 820px;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.video-container {
    max-width: 820px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.chapters {
    border: 1px solid #1B3667;
    overflow-x: auto;
    max-width: 820px;
    margin: 0 auto;
    padding-bottom: 10px; /* Add padding to create space for the scrollbar */

    .chapter-header {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        color: #fff;
        background: linear-gradient(90deg, #1B3667 0%, #00A198 99.29%);
        padding: 10px 6px;
        margin-bottom: 10px;
    }

    .chapters-container {
        display: flex;
        flex-direction: row;
        height: 230px;
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 20px; /* Add padding to position the scrollbar 20px above the bottom */
        @media screen and (max-width: 768px) {
            overflow-x: unset;
            overflow-y: scroll;
            display: block;
            padding-bottom: 10px;
        }
        .chapter {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            width: 200px;
            cursor: pointer;
            color: #636466;
            text-decoration: none;
            @media screen and (max-width: 768px) {
                display: block;
                width: 80%;
                width: calc(100% - 30px);
                position: relative;
                padding-left: 50px;
                margin-left: 10px;
                margin-right: 10px;
                &::before {
                    content: "";
                    width: 38px;
                    height: 38px;
                    position: absolute;
                    left: 0;
                    background-image: url(../img/content/play-icon.svg);
                    background-repeat: no-repeat;
                }
            }

            &:first-child {
                margin-left: 10px;
                margin-right: 20px;
            }

            &:last-child {
                margin-right: 10px;
            }

            &:not(:first-child):not(:last-child) {
                margin-right: 20px;
            }

            strong {
                display: block;
                width: 100%;
                white-space: normal; /* Ensure strong items run onto a new line */
            }

            span {
                display: block;
                width: 100%;
            }

            img.thumb {
                width: 200px;
                height: 105px;
                margin-bottom: 10px;
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
}

.subpage.sitemap {
    ul, li {
        list-style: none;
        @media screen and (max-width: 768px) {
            padding-left: 0;
            margin-left: 0;
        }
    }
    ul {
        li {
            &::before {
                display: none;
            }
            margin-bottom: 30px;
            color: $darkred;
            &.sitemap-header {
                margin-bottom: 10px;
            }
            strong { color: #636466; }
            a {
                font-size: 18px;
                line-height: 24px;
                color: $darkred;
                text-decoration: underline;
                font-weight: 700;
                @media screen and (max-width: 768px) {
                    font-size: 16px;
                }
            }
            &.indented {
                margin-left: 20px;
                margin-bottom: 15px;
            }
        }
    }
}
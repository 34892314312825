footer {
    font-weight: 400;
    color: #fff;
    padding: 50px 10px 100px;
    position: relative;
    z-index: 1;
    background-color: #012740;
    font-size: 14px;
    line-height: 18px;

    .container { 
        max-width: 1212px;
        @media screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    nav {
        ul, li {
            text-decoration: none;
            display: inline-block;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        li {
            position: relative;
            padding-right: 40px;
            a {
                text-decoration: underline;
                color: #C1E5E8!important;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
            }
            &::after {
                content: "|";
                position: absolute;
                right: 15px;
            }

            @media screen and (max-width: 768px) {
                padding-right: 10px;
                &::after { right: 0px; }
            }

            @media screen and (max-width: 389px) {
                &::after { display: none; }
            }

            @media screen and (max-width: 382px) {
                width: 100%;
                padding-bottom: 20px;
                &:last-of-type {
                    padding-bottom: 0;
                }
            }


            &:last-of-type::after {
                content: unset;
            }
        }
        ul.isi-links {
            li {
                @media screen and (max-width: 639px) {
                    &:first-of-type {
                        padding-bottom: 20px;
                    }
                }
                &::after {
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
        padding-bottom: 20px;
    }
    @media screen and (min-width: 769px) {
        .servier-logo {
            width: 100%;
            text-align: right;
        }
    }
    @media screen and (max-width: 768px) {
        .row {
            .column.column-75 {
                flex: 0 0 100%;
                max-width: 100%;   
            }
            .column {
                text-align: center;
                img {
                    margin-bottom: 15px;
                }
            }
            &:last-of-type {
                flex-direction: column-reverse;
            }
        }
    }
}
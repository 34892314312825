.flex {
    display: flex!important;
}

.vertical-align {
    align-items: center!important;
    justify-content: center!important;
}

.center {
    text-align: center!important;
}

.left {
    text-align: left!important;
}

.right {
    text-align: right!important;
}

.justify-left {
    justify-content: left!important;
}

.justify-right {
    justify-content: right!important;
}

.normal {
    font-weight: 400!important;
}

.strong {
    font-weight: 700;
}

strong {
    &.light {
        font-weight: 500;
    }
}

.hyphenate {
    hyphens: auto;
    overflow-wrap: break-word; /* or word-break: break-all; */
}

.darkblue {
    color: $darkblue;
}

.bodygrey {
    color: #636466!important;
}

.tablegrey {
    color: #666666!important;
}

.red {
    color: $lightred;
}

.green {
    color: $green;
}

.mutedgreen {
    color: $mutedgreen;
}

.margin-bottom-60 {
    margin-bottom: 60px!important;
}
.margin-bottom-50 {
    margin-bottom: 50px!important;
}

.margin-top-35 {
    margin-top: 35px!important;
}

.margin-top-45 {
    margin-top: 45px!important;
}

.margin-bottom-30 {
    margin-bottom: 30px!important;
}

.margin-bottom-20 {
    margin-bottom: 20px!important;
}
.margin-bottom-15 {
    margin-bottom: 15px!important;
}
.margin-bottom-10 {
    margin-bottom: 10px!important;
}
.margin-bottom-5 {
    margin-bottom: 5px!important;
}
.margin-bottom-4 {
    margin-bottom: 4px!important;
}

.margin-bottom-0 {
    margin-bottom: 0px!important;
}

.margin-top-60 {
    margin-top:60px!important;
}

.margin-top-50 {
    margin-top:50px!important;
}

.margin-top-30 {
    margin-top:30px!important;
}

.margin-top-25 {
    margin-top:25px!important;
}

.margin-top-20 {
    margin-top:20px!important;
}

.margin-top-15 {
    margin-top:15px!important;
}

.margin-top-0 {
    margin-top: 0px!important;
}

.padding-top-10 {
    padding-top: 10px!important;
}

.padding-top-15 {
    padding-top: 15px!important;
}

.padding-top-20 {
    padding-top: 20px!important;
}

.padding-top-30 {
    padding-top: 30px!important;
}

.padding-left-10 {
    padding-left: 10px!important;
}

.padding-left-20 {
    padding-left: 20px!important;
}

.mobile-padding-top-20 {
    @media screen and (max-width: 768px) {
        padding-top: 20px;
    }
}

.text-indent-left-15 {
    display: block;
    text-indent: 15px;
}

.desktop {
    display: block!important;
}

.mobile {
    display: none!important;
    &.mobile-full-width {
        width: 100%;
    }
}

.relative {
    position: relative;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

hr {
    max-width: 1130px;
    &.green {
        border-color: $green;
    }
    &.red {
        border-color: #F398A4;
    }
    &.blue {
        border-color: $darkblue;
    }
    &.grey {
        border-color: $bordergrey;
    }
    &.short {
        max-width: 920px;
    }
}

#dosage-reductions, #isi-warnings-and-precautions, #adjustments {
    scroll-margin-top: 180px;
}

p.clickthrough {
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
        line-height: 16px;
    }
}

a.with-arrow {
    color: $lightred;
    font-weight: 700;
    position: relative;
    font-size: 18px;
    @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;
    }
    &::after {
        content: "›";
        position: absolute;
        right: -10px;
    }
}

.references {
    p {
        position: relative;
        display: inline-block;
        padding-left: 5px;
        font-size: 12px!important;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }
    sup {
        &.first {
            font-size: 12px!important;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            position: absolute;
            left: -2px;
            top: 1px;
        }
    }
}

.box {
    background: rgba(255, 255, 255, 0.15);
    margin: 20px 10px;
    padding: 20px;
    position: relative;
    &::before, &::after {
        content: "";
        height: 2px;
        width: 100%;
        background: linear-gradient(105deg, rgba(193, 229, 232, 0.90) 18.82%, rgba(0, 161, 152, 0.90) 82.53%);
        position: absolute;
        top: 0;
        left: 0;
    }
    &::after {
        top: unset;
        bottom: 0;
    }
}

.footnotes {
    background: linear-gradient(180deg, #306A95 0%, #1C4F75 100%);
    margin-top: 120px;
    padding-top: 0;
    .note {
        padding-top: 20px;
    }
    &.margin-top-0 {
        margin-top: -1px!important;
    }
    p {
        color: $white;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.24px;
        margin-bottom: 10px;
    }
    a.button {
        position: relative;
        margin: 40px 0;
        height: 70px;
        letter-spacing: 0;
        border-radius: 0;
        z-index: 4;
        padding: 25px 80px 25px 40px;
        border: 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        background: rgba(255, 255, 255, 0.15);
    
        &::before {
            display: block;
            background: rgba(255, 255, 255, 0.15);
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }
    
        &::after {
            background-image: url(../img/content/arrow-red-background.svg);
            width: 34px;
            height: 34px;
            right: 25px;
            top: 18px;
            position: absolute;
            content: "";
            z-index: 3;
        }
    
        &::before,
        &::after {
            border: 0;
        }
    
        &::before {
            background:
                linear-gradient(to right, #D13E4E 4px, transparent 4px) 0 0,
                linear-gradient(to right, #D13E4E 4px, transparent 4px) 0 100%,
                linear-gradient(to left, #D13E4E 4px, transparent 4px) 100% 0,
                linear-gradient(to left, #D13E4E 4px, transparent 4px) 100% 100%,
                linear-gradient(to bottom, #D13E4E 4px, transparent 4px) 0 0,
                linear-gradient(to bottom, #D13E4E 4px, transparent 4px) 100% 0,
                linear-gradient(to top, #D13E4E 4px, transparent 4px) 0 100%,
                linear-gradient(to top, #D13E4E 4px, transparent 4px) 100% 100%;
            background-repeat: no-repeat;
            background-size: 20px 20px;
            z-index: 5; /* Ensure the corners are on top */
        }
        &:hover::before {
            background:
                linear-gradient(to right, #FFF 4px, transparent 4px) 0 0,
                linear-gradient(to right, #FFF 4px, transparent 4px) 0 100%,
                linear-gradient(to left, #FFF 4px, transparent 4px) 100% 0,
                linear-gradient(to left, #FFF 4px, transparent 4px) 100% 100%,
                linear-gradient(to bottom, #FFF 4px, transparent 4px) 0 0,
                linear-gradient(to bottom, #FFF 4px, transparent 4px) 100% 0,
                linear-gradient(to top, #FFF 4px, transparent 4px) 0 100%,
                linear-gradient(to top, #FFF 4px, transparent 4px) 100% 100%;
                background-repeat: no-repeat;
                background-size: 20px 20px;
        }
        &:hover {
            background-color: #D13E4E;
            color: #fff;
        }
    }
}

@media screen and (max-width: 768px) {
    .desktop {
        display: none!important;
    }
    
    .mobile {
        display: block!important;
    }

    img.mobile {
        margin: 0 auto;
        width: 100%;
        &.smaller {
            width: 75%;
        }
    }

    .margin-top-mobile-30 {
        margin-top: 30px;
    }
    .footnotes {
        margin-top: 50px;
        .right {
            text-align: center!important;
            a.button {
                width: 100%;
                height: auto; /* Adjust height to auto */
                display: block;
                position: relative;
                white-space: normal;
                text-align: left;
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
                &::after {
                    top: calc(50% - 17px);
                }
            }
        }
    }
    .mobile-overflow-x {
        overflow-x: scroll;
        img {
            min-width: 600px;
        }
    }
    
}
.overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #0A162C;
    opacity: 70%;
    z-index: 100;
    @media screen and (max-width: 768px) {
        top: 140px;
        &.scrolled {
            top: 88px;
        }
        &.cookies {
            top: 110px;
        }
    }
}

#leaving {
    display: none;
    h3 {
        padding-right: 40px;
        padding-left: 40px;
        @media screen and (max-width: 400px) {
            margin-bottom: 0;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    div.modal-wrap {
        width: 100%;
        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column-reverse;
            flex-wrap: wrap;
        }
        div {
            width: 50%;
            display: inline-block;
            @media screen and (max-width: 768px) {
                min-width: 100%;
            }
        }
        a {
            width: 50%;
            display: inline-block;
            margin-left: 5px!important;
            margin-right: 5px!important;
            @media screen and (max-width: 768px) {
                width: 100%;
                &.button {
                    margin: 25px auto 0!important;
                }
                &.bold {
                    margin-top: 0;
                } 
                &.return {
                    width: unset;
                }
            }
        }
    }
    a.button {
        max-width: 182px;
        margin: 0 auto;
        padding-left: 40px;
        @media screen and (max-width: 400px) {
            padding-left: 30px;
            margin-top: 10px;
            padding-right: 40px;        
        }
        min-width: unset;
    }
}

.modal {
    display: none;
    padding: 25px 25px 30px 25px;
    border-radius: 25px;
    width: 830px;
    position: fixed;
    z-index: 999;
    top: 25%;
    left: 20%;
    left: calc(50% - 415px);
    background-color: $white;
    text-align: center;
    span.close {
        background-color: $lightred;
        color: $white;
        width: 27px;
        height: 27px;
        text-align: center;
        position: absolute;
        top: 25px;
        right: 25px;
        border-radius: 50%;
        font-size: 1em;
        font-weight: 400;
        padding-top: 2px;
        cursor: pointer;
    }
    h3 {
        color: $darkblue;
        font-weight: 600;
        margin-bottom: 30px;
    }
    div {
        margin: 0 auto;
        a {
            display: block;
            text-align: center;
            max-width: 400px;
            font-size: 16px;
            margin: 0 auto;
            cursor: pointer;
            
            &.semi-bold {
                font-weight: 600;
                margin-top: 25px;
                color: $grey;
                text-decoration: underline;
                display: inline-block;
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: calc(100% - 20px);
        left: 10px;
        right: 10px;
        padding: 60px 10px 20px;
        span.close {
            top: 15px;
            right: 15px;
        }
        h3 {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 20px;
        }
        div {
            a {
                font-size: 14px;
                &.button {
                    padding-left: 15px;
                }
            }
            img {
                position: absolute;
                top: 12px;
                right: 10px;
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }
    @media screen and (max-width: 400px) {
        div {
            a {
                font-size: 14px;
                &.button {
                    min-width: 100%;
                    max-width: 100%;
                    white-space: normal;
                    line-height: 19px;
                    padding: 10px 30px;
                    height: unset;
                    &::after {
                        top: calc(50% - 9px);
                    }
                }
            }
        }
    }
}

#hcp-only {
    a.button {
        &:hover {
            color: #D13E4E;
        }
    }
}
#isi, .overlay-isi {
    h2, h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #B7DDE2;
        margin-bottom: 16px;
    }
    h2 { margin-top: 12px; }
    h3 {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 4px;
        margin-top: 12px;
    }
    p {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 5px;
        color: $white;
    }
    a {
        color: #B7DDE2;
        text-decoration: underline;
        padding-top: 20px;
    }
    .isi-section {
        .row {
            display: block;
        }
    }
}

.overlay-isi {
    box-shadow: 0px -10px 15px -3px rgba(0,0,0,0.1);
    z-index: 999;
    position: fixed;
    max-width: calc(100% + 2.5rem);
    left: 1.25rem;
    right: 1.25rem;
    bottom: 0;
    height: 150px;
    background: $white;
    display: block;
    padding: 5px 25px 25px;
    border-radius: 20px 20px 0 0px;
    color: $body;
    @media screen and (min-width: 769px) {
        max-width: 1260px;
        margin: 0 auto;
    }
    h2, h3 {
        color: #1B3667;
    }
    p {
        color: $body;
    }
    img {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 27px;
        height: 27px;
        cursor: pointer;
    }
    @media screen and (max-width: 768px) {
        height: 200px;
        width: calc(100% - 20px);
        left: 10px;
        right: 10px;
        padding: 10px;
        .row {
            flex-direction: column-reverse;
        }
        h2 {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 6px;
        }
        h3 {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 6px;
        }
        img {
            position: absolute;
            top: 12px;
            right: 10px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
}

.showing-cookies .overlay-isi {
    bottom: 82px;
    @media screen and (max-width: 1100px) {
        bottom: 110px;
    }

    @media screen and (max-width: 600px) {
        bottom: 150px;
    }

    @media screen and (max-width: 400px) {
        bottom: 200px;
    }
}

#isi {
    scroll-margin: 165px;
    background-color: #013A5D;
    position: relative;
    padding-bottom: 60px;
    .isi-section {
        .column:first-of-type p {
            margin-bottom: 24px;
        }
    }
    p.pfi {
        margin-top: 24px;
    }
    &::before {
        content: "";
        display: block;
        background: linear-gradient(180deg, #1C4F75 0%, #013A5D 100%);
        height: 50px;
        @media screen and (max-width: 768px) {
            height: 20px;
        }
    }
    @media screen and ( max-width: 768px) {
        scroll-margin: 95px;
    }
}
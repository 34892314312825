.cookies {
    display: none;
    position: fixed;
    width: 100%;
    height: 82px;
    padding: 16px 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(10,121,134);
    background: linear-gradient(155deg, rgba(10,121,134,1) 0%, rgba(27,54,103,1) 100%);
    z-index: 999;
    @media screen and (min-width: 640px) and (max-width: 1179px) {
        height: 110px;
    }
    .container {
        max-width: 1212px;
    }
    h3 {
        font-weight: 600;
        color: $white;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 4px;
    }
    p {
        margin-bottom: 0;
        color: $white;
        font-size: 12px;
        margin-bottom: 16px;
        line-height: 14px;
        padding-right: 20px;
    }
    img {
        position: absolute;
        top: -25px;
        left: -40px;
        width: 27px;
        height: 27px;
        cursor: pointer;
    }
    a.button {
        font-size: 15px;
        line-height: 15px;
        height: 50px;
        min-width: 212px;
        &:hover {
            color: #D13E4E;
        }
        &::after {
            top: 15px;
        }
    }
    
    @media screen and (min-width: 640px) and (max-width: 1179px) {
        .column-80 {
            flex: 0 0 75%;
            width: 75%;
            max-width: 75%!important;
        }
        .column-20 {
            flex: 0 0 25%;
            width: 25%;
            max-width: 25%!important;
        }
        a.button {
            font-size: 15px;
            line-height: 15px;
            height: 50px;
            min-width: unset;
            padding: 15px 30px 15px 15px;
            font-size: 12px;
            position: relative;
            left: -20px;
        }
    }
    @media screen and (max-width: 639px) {
        width: 100%;
        height: auto;
        padding: 10px 20px;
        .container {
            padding-left: 0;
            padding-right: 0;
        }
        .column {
            width: 100%;
            max-width: 100%!important;
        }
        .column-20 {
            text-align: center;
        }
        h3, p {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 10px;
        }

        img {
            position: absolute;
            top: -10px;
            left: -35px;
        }
        a.button {
            @media screen and (max-width: 768px) {
                height: 35px;
                min-width: unset;
                width: auto;
                font-size: 14px;
                padding: 7px 35px 5px 20px;
            }
            &::after {
                top: 15px;
                @media screen and (max-width: 768px) {
                    top: 5px;
                }
            }
        }
    }
}

body.modal-active {
    .cookies {
        display: none;
    }
}
body.home {
    background-color: rgb(2, 38, 65);
    background-image: url('../img/hero/home-desktop.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    @media screen and (max-width: 768px) {
        background: rgb(1,43,73);
        background: linear-gradient(90deg, rgba(1,43,73,1) 0%, rgba(2,38,65,1) 100%);
        section.hero.home { 
            h1 {
                margin-top: 300px;
                @media screen and (max-width: 1177px) { 
                    margin-top: 400px;
                }
                @media screen and (max-width: 768px) {
                    margin-top: 400px;
                }
                @media screen and (max-width: 600px) {
                    margin-top: 500px;
                }
                @media screen and (max-width: 500px) {
                    margin-top: 400px;
                }
                @media screen and (max-width: 450px) {
                    margin-top: 350px;
                }
            }
        }
    }


}

.content-callout {
    background-color: $lightblue;
    border-radius: 10px;
    padding: 25px 50px;
    margin: 25px 40px;

    .row {
        margin-top: 12px;
        margin-bottom: 12px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .column {
            &:first-of-type {
                padding-right: 50px;
            }
        }
    }

    h3 {
        color: $darkblue;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        padding: 30px 20px;
        margin: 10px 0;

        h3 {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.servier-one-container {
    margin-top: 25px;
    position: relative;

    p {
        font-size: 18px;
        line-height: 22px;
    }

    .column-34 {
        img.servier-one-logo {
            display: block;
            @media screen and (min-width: 769px) {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }

    .column-66 {
        img.servier-one-logo {
            display: none;
            margin: 20px auto;
        }
    }

    @media screen and (max-width: 768px) {
        .column-34 {
            flex: 0 0 100%;
            max-width: 100%;

            img.servier-one-logo {
                display: none;
            }
        }

        .column-66 {
            flex: 0 0 100% !important;
            max-width: 100% !important;

            img.servier-one-logo {
                display: block;
                margin-bottom: 20px;
                width: 157px;
                height: 78px;
            }
        }

        h2 {
            font-size: 24px;
            line-height: 28px;
        }

        p {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.phone-callout {
    font-size: 20px;
    line-height: 23px;

    h3 {
        color: $green;
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;

        @media screen and (max-width: 768px) {
            text-align: left;
        }

        &::after {
            display: none !important;
        }
    }

    >span {
        position: relative;
        top: 5px;
    }

    small {
        font-size: 11px;
        font-weight: 700;
        position: relative;
        top: -5px;
        display: block;
        width: 100%;

        span {
            font-variant: small-caps;
            font-size: 0.8em;
        }
    }
}

.column {
    &.tick,
    &.dollar,
    &.prescription,
    &.tools,
    &.website,
    &.email,
    &.mouse,
    &.water,
    &.crushed-pills,
    &.phone {
        padding-left: 100px;
        position: relative;
        min-height: 70px;
        @media screen and (max-width: 1180px) {
            width: 100%!important;
            max-width: 100%!important;
            flex: 0 0 100!important;
            margin-top: 30px;
            padding-left: 0;
            padding-top: 50px;
            padding-bottom: 30px;
            &:first-of-type {
                margin-top: 10px;
            }
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            background-repeat: no-repeat;
            background-size: contain;
            height: 70px;
            width: 70px;
            @media screen and (max-width: 1180px) {
                top: -30px;
                left: calc(50% - 35px);
            }
        }
    }

    &.tick{
        &::before {
            background-image: url("../img/content/tick.svg");
        }
    }

    &.mouse::before {
        background-image: url("../img/content/mouse.svg")!important;
        content: "";
        position: absolute;
        left: 0!important;
        top: 10px!important;
        background-repeat: no-repeat;
        background-size: contain!important;
        height: 70px!important;
        width: 70px!important;
        transform: unset!important;
        @media screen and (max-width: 1180px) {
            top: 0!important;
            left: calc(50% - 35px)!important;
        }
    }

    &.prescription::before {
        background-image: url("../img/content/prescription.svg");
    }

    &.dollar::before {
        background-image: url("../img/content/dollar.svg");
    }

    &.tools::before {
        background-image: url("../img/content/tools.svg");
    }

    &.crushed-pills::before {
        @media screen and (max-width: 1180px) {
            top: 0!important;
            left: 0!important;
        }
        @media screen and (max-width: 768px) {
            top: 30px!important;
        }
        background-image: url("../img/content/crushed-pills.svg");
    }
    &.water::before {
        @media screen and (max-width: 1180px) {
            top: 0!important;
            left: 0!important;
        }
        @media screen and (max-width: 768px) {
            top: 30px!important;
        }
    }

    &.website {
        padding-top: 12px;

        a {
            font-weight: 700;
        }

        &::before {
            background-image: url("../img/content/website.svg");
        }
    }

    &.email {
        padding-top: 12px;

        a {
            font-weight: 700;
        }

        &::before {
            background-image: url("../img/content/email.svg");
            width: 40px;
            height: 40px;
            top: 24px;
        }
    }

    &.phone {
        padding-top: 2px;

        a {
            text-decoration: none;
            font-weight: 700;
        }

        &::before {
            background-image: url("../img/content/phone.svg");
            width: 33px;
            height: 33px;
            top: 3px;
            left: 22px;
        }
    }

    &.or {
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;

        &.or1 {
            padding-right: 30px;

            @media screen and (max-width: 768px) {
                padding-right: 0px;
            }
        }
    }

    @media screen and (max-width: 768px) {

        &.website,
        &.phone,
        &.email {
            flex: 0 0 100% !important;
            max-width: 450px !important;
            padding: 10px 10px 10px 50px;
        }

        &.website,
        &.phone,
        &.or {
            text-align: left;

            &::before {
                height: 44px;
                width: 44px;
                left: 0;
            }
        }

        &.email {
            &::before {
                top: 6px;
                width: 42px;
            }
        }
    }
}

.register-callout,
.embryo-callout {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;

    h3,
    h4 {
        font-size: 20px;
        line-height: 24px;
        color: $darkblue;
        font-weight: 500;
        position: relative;
        display: inline-block;

        a {
            font-weight: 700;
        }

        &::before {
            content: "";
            position: absolute;
            background-image: url("../img/home/circle.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 50px;
            height: 60px;
            left: -25px;
            top: -15px;
        }

        @media screen and (max-width: 900px) {
            width: 275px;
            text-align: left;

            &::before {
                left: -2em;
                top: -2em;
                width: 9em;
                height: 9em;
            }
        }
    }
}

.callout-boxes {
    > .column {
        background: rgba(255, 255, 255, 0.15);
        position: relative;
        padding: 30px;
        &:first-of-type {
            margin-right: 30px;
            @media screen and (min-width: 640px) and (max-width: 1177px) {
                margin-left: 10px;
            }
            @media screen and (max-width: 768px) {
                margin-right: unset;
            }
        }
        &:last-of-type {
            margin-left: 30px;
            @media screen and (min-width: 640px) and (max-width: 1177px) {
                margin-right: 10px;
            }
            @media screen and (max-width: 768px) {
                margin-left: unset;
                margin-top: 30px;
            }
        }
        &::before, &::after {
            content: "";
            background: linear-gradient(105deg, rgba(193, 229, 232, 0.90) 18.82%, rgba(0, 161, 152, 0.90) 82.53%);
            height: 2px;
            width: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0
        }
        &::after {
            top: unset;
            bottom: 0;
        }
        p {
            color: $white;
            font-family: "Titillium Web";
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 20px!important;
        }
        .cta-button {
            color: $white;
            height: 42px;
            width: 190px;
            padding: 10px 10px 12px 5px;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 15px;
            min-width: 190px;
            &:hover {
                color: #D13E4E;
            }
            &::after {
                top: 9px;
            }
        }
        @media screen and (max-width: 768px) {
            .column-20, .column-80 {
                width: 100%;
                max-width: 100%;
            }
            .column-20 {
                text-align: center;
            }
            .column-80 {
                text-align: center;
                p {
                    text-align: left;
                }
            }
        }
    }
}

.embryo-callout {
    align-self: center;
    margin-top: 0;

    h4 {
        &::before {
            width: 85px;
            height: 100px;
            top: -25px;
            left: -45px;

            @media screen and (max-width: 768px) {
                width: 125px;
                height: 147px;
                left: -70px;
                top: -20px;
            }
        }
    }
}

#review {
    margin: 0px 0 25px;
}

#home-pfs {
    h3 {
        font-size: 26px;
        line-height: 30px;
        color: $white;
        font-weight: 700;
        margin-top: 70px;
        @media screen and (max-width: 768px) {
            margin-top: 5px;
            margin-bottom: 35px!important
        }
    }

    .pfs-text {
        margin-top: 35px;
    }

    .pfs-text01 {
        color: $white;
        text-align: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        margin-bottom: 5px;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
    }

    .pfs-text02 {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
        letter-spacing: -0.24px;
        color: $white;
        text-align: left;
    }

    .icon-61, .icon-74 {
        max-width: 180px;
        img {
            min-width: 180px;
        }
    }

    div {
        @media screen and (max-width: 768px) {
            margin: 0 auto;
            p {
                text-align: center!important;
            }
        }
    }
}

#review {
    h1 {
        color: #C1E5E8;
        font-size: 22px;
        line-height: 26px;
    }
    a.button {
        letter-spacing: 0;
    }
}

#pfs h3 {
    margin-top: 35px;
}

#pfs-footnote {
    margin: 15px 50px 0px;

    @media screen and (max-width: 768px) {
        margin-left: 20px;
    }
}